import React, { Component } from 'react';

export default class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <header>
                <div className="w3-main">
                    <div id="header-bar" className="w3-container opaque-green">
                        <h1 className="w3-center w3-myfont"><span id="author-name">Xal-Zul</span></h1>
                    </div>
                </div>
            </header>
        );
    }
}
