import React, { Component } from 'react';
import NavButtons from './NavButton';

import githubImage from '../resources/images/social/github.png';
import linkedinImage from '../resources/images/social/linkedin.png';
import hamMenuIcon from '../resources/icons/ham-menu.png';

export default class SideBarContainer extends Component {
    constructor(props) {
        super(props);

        //let CVLink = encodeURI("http://www.akashiclabs.co.za/Eynar Roshev CV.pdf");
        //let blogLink = "http://www.blog.akashiclabs.co.za";
        //let linkedInLink = "https://www.linkedin.com/in/eynar-roshev";
        let githubLink = "https://www.github.com/XalZul";
	let emailLink = "mailto:xalzultailsscales@gmail.com";
	let twitchLink = "https://www.twitch.tv/xalzul";
	let youtubeLink = "https://www.youtube.com/@xal-zul";
	let twitterLink = "https://twitter.com/xalzul";

	let discordLink = "https://discord.com/invite/QfmZqQ3AF7";

        this.state = {
            navButtons: [
                {
                    name: "Home",
                    onPress: (view) => this.props.handleViewChange("Home"),
                    external: false,
                },
                // {
                //     name: "About Me",
                //     onPress: (view) => this.props.handleViewChange("About Me"),
                //     external: false,
                // },
                /*{
                    name: "Virtual Assistant",
                    onPress: (view) => this.props.handleViewChange("Virtual Assistant"),
                    external: false,
                },*/
                /*{
                    name: "Blog",
                    onPress: () => window.location.assign(blogLink),
                    external: true,
                },
                {
                    name: "LinkedIn",
                    onPress: () => window.location.assign(linkedInLink),
                    external: true,
                },*/
		{
		    name: "Twitter",
		    onPress: () => window.location.assign(twitterLink),
		    external: true,
		},
		{
		    name: "Twitch",
		    onPress: () => window.location.assign(twitchLink),
		    external: true,
		},
		{
		    name: "YouTube",
		    onPress: () => window.location.assign(youtubeLink),
	            external: true,
		},
		{
		    name: "Discord Server",
		    onPress: () => window.location.assign(discordLink),
		    external: true,
		},
                {
                    name: "GitHub",
                    onPress: () => window.location.assign(githubLink),
                    external: true,
                },
		{
		    name: "Email",
		    onPress: () => window.location.assign(emailLink),
		    external: true,
		},
                // {
                //     name: "Website Portfolio",
                //     onPress: (view) => this.props.handleViewChange("Portfolio"),
                //     external: false,
                // },
                /*{
                    name: "CV",
                    onPress: () => window.location.assign(CVLink),
                    external: false
                }*/
            ],
            display: 'none'
        };
        this.handleOpenClose = this.handleOpenClose.bind(this);
    }

    handleOpenClose() {
        if (this.state.display === 'block') {
            this.setState({
                display: 'none'
            });
        }
        else if (this.state.display === 'none') {
            this.setState({
                display: 'block'
            });
        }
    }

    render() {
        return (
            <div>
                <button id="hamburger-menu-button" className="w3-button w3-xlarge w3-hide-large" onClick={this.handleOpenClose}>
                    <img id="hamburger-menu" src={hamMenuIcon} alt="Menu" />
                </button>

                <div id="nav-buttons"
                    //  className="w3-sidebar w3-bar-block w3-collapse w3-card w3-col m3"
                    className="w3-sidebar w3-animate-left w3-bar-block w3-collapse"
                    style={{ display: this.state.display }}>
                    <NavButtons buttons={this.state.navButtons} />
                </div>
            </div>
        );
    }

}
