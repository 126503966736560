import React, { Component } from 'react';
import CentralImage from '../components/CentralImage';
import SideBarContainer from '../components/SideBarContainer';
import BlogPosts from '../components/BlogPost';
import Header from '../components/Header';
//import mainImage from '../resources/images/0.jpg';
import reactIcon from '../resources/icons/128px-React-icon.svg.png';
import fursonaImage from "../resources/images/fursona.jpeg";

export default class Home extends Component {
  // The center image gets fetched from LinkedIn
  //linkedInImageURL = "../resources/images/self-LI.jpeg";

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="App">
        <div className="w3-row"><Header /></div>
        <div className="w3-row">
          <div className="w3-quarter w3-container w3-margin-top">
            <SideBarContainer handleViewChange={this.props.handleViewChange} />
          </div>
          <div className="w3-container w3-half w3-animate-bottom">
            <div id="central-image-container" className="w3-container w3-center w3-margin-top">
              <CentralImage source={fursonaImage} alt="Green Dragon" className="w3-circle w3-margin-top w3-margin-bottom" />
              <p>Hi there! I am Xal-Zul, a creative Dragon 🐉 from South Africa 🇿🇦 working in <span className="span-steam w3-text-green">STEAM</span><span className="w3-text-green"> (Science, Technology, Engineering, Art, Mathematics)</span> and with demonstrable software development industry experience in the mining and vehicle simulation sectors. I enjoy collaborating 💬 with others.</p>

              <p>In the sidebar, you can find links to my various social media.</p>

              <p>Feel free to get in touch with me at any time.</p>

	    {/*<p>This website was built utilising ReactJS.<img id="react-icon" src={reactIcon} alt="React" /></p>*/}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
